<template>
  <div>
    <div class="flex_row normal_height content_between">

      <!-- 灯光设置部分 -->
      <div class="flex_column_start content_between">
        <div class="flex_column_start content_between light_height">
          <!-- 灯光模式 -->
          <span lang="LightMode">灯光模式</span>
          <select
            v-model="mode"
            @change="handleModeChange"
            class="light_normal_width light_margin">
            <option 
            v-for="item in lightModeOptions" 
            :key="item.value" 
            :value="item.value">{{item.option}}</option>
          </select> 

          <!-- 灯光亮度 -->
          <span lang="Brightness">亮度</span>
          <div class="flex_row_center">
            <el-slider
            v-model="brightness"
            @change="handleBrightnessChange"
            :show-tooltip="false"
            :min="min"
            :max="max"
            class="light_normal_width light_margin"
            :disabled="brightnessDisable"></el-slider>
            <span>{{brightness + 1}}</span> 
          </div>

          <!-- 灯光速度 -->
          <span lang="Speed">速度</span>  
          <div class="flex_row_center">
            <el-slider
            v-model="speed"
            @change="handleSpeedChange"
            :show-tooltip="false"
            :min="min"
            :max="max"
            class="light_normal_width light_margin"
            :disabled="speedDisable"></el-slider>
            <span>{{speed + 1}}</span> 
          </div>
        </div>
       

        <div class="flex_column_start content_between moving_height">
          <!-- 移动时关灯 -->
          <div class="flex_row_center content_between moving_off_width">
            <span class="text_width" lang="MovingOffLight" style="text-align: left;">移动时关灯</span>
            <el-switch
            v-model="movingOffState"
            @change="handleMovingOffChange"
            :disabled="lightState == false"></el-switch>
          </div>

          <!-- 放停后关灯时间 -->
          <div class="flex_row_center content_between light_normal_width">
            <span class="text_width" lang="LightOffTime" style="text-align: left;">放停后灯光关闭时间</span>
            <select
            v-model="lightOffTime"
            @change="handleLightOffTimeChange"
            class="stop_off_time_select"
            :disabled="lightState == false">
            <option 
            v-for="item in lightOffTimeOptions" 
            :key="item.value" 
            :value="item.value">{{item.option}}</option>
            </select> 
          </div>
        </div>

      </div>

      <!-- 颜色设置部分 -->
      <div v-show="!colorDisable" >
        <div class="flex_column_start normal_height content_between">
          <!-- 自定义颜色 -->
          <span lang="CustomColor">自定义颜色</span>
          <img 
          :src="colorbarSrc"
          @click="getPixelColor($event,colorbarSrc)"
          ref="imageElement"
          class="light_normal_width"
          draggable="false">

          <!-- 预览 -->
          <span lang="Preview">预览</span>
          <div class="flex_row_center" >
            <div class="preview_color" :style="{backgroundColor:backColor}">
            </div>
            <div class="flex_column" style="align-items: flex-end;">
              <div class="flex_row_center">
                <span lang="Red">R:</span>
                <el-input-number
                v-model="r"
                @change="handleColorChange"
                :min="0"
                :max="255"
                size="mini">
                </el-input-number>
              </div>
              <div class="flex_row_center">
                <span lang="Green">G:</span>
                <el-input-number
                v-model="g"
                @change="handleColorChange"
                :min="0"
                :max="255"
                size="mini">
                </el-input-number>
              </div>
              <div class="flex_row_center">
                <span lang="Blue">B:</span>
                <el-input-number
                v-model="b"
                @change="handleColorChange"
                :min="0"
                :max="255"
                size="mini">
                </el-input-number>
              </div>
            </div>
          </div>     
           
          <!-- 预设 -->
          <span lang="Preset">预设</span>
          <div class="flex_column">
            <div class="flex_row">
              <div 
              v-for="index in 7"
              :key="index">
              <img 
              :src="presetPath + (index + 0) + '.png'"
              @click="getPixelColor($event, presetPath + (index + 0) + '.png')"
              ref="imageElement"
              class="preset_color"
              draggable="false">
              </div>
            </div>
            <div class="flex_row">
              <div 
              v-for="index in 7"
              :key="index">
              <img 
              :src="presetPath + (index + 7) + '.png'"
              @click="getPixelColor($event,presetPath + (index + 7) + '.png')"
              ref="imageElement"
              class="preset_color"
              draggable="false">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import HIDHandle from '@/assets/js/HIDHandle'
  import UserConvert from '@/assets/js/UserConvert'

  export default {
    data() {
      return {
        mode:0,
        lastMode:0,
        min:0,
        max:9,
        lightModeOptions:[],
        brightnessDisable:false,
        brightness:5,
        lastBrightness:5,
        speedDisable:false,
        speed:5,
        lastSpeed:5,
        lightState:true,
        movingOffState:false,
        lightOffTime:6,
        lastLightOffTime:6,
        lightOffTimeOptions:[],
        colorDisable:false,
        color:[255,0,255],
        lastColor:[255,0,255],
        r:255,
        g:0,
        b:255,
        backColor:'rgb(255,0,255)',
        colorbarSrc:"/img/color_bar.png",
        presetPath:"/img/colors/color",

        widthRatio:1,
        heightRatio:1,
      }
    },
    methods: {
      async handleModeChange() {
        if(await HIDHandle.Set_MS_LightMode(this.mode)) {
          this.lastMode = this.mode;
          this.updateDisableState();
        }
        else {
          this.mode = this.lastMode;
        }
      },
      async handleBrightnessChange() {
        if(await HIDHandle.Set_MS_LightBrightness(this.brightness)) {
          this.lastBrightness = this.brightness;
        }
        else {
          this.brightness = this.lastBrightness;
        }
      },
      async handleSpeedChange() {
        if(await HIDHandle.Set_MS_LightSpeed(this.speed)) {
          this.lastSpeed = this.speed;
        }
        else {
          this.speed = this.lastSpeed;
        }
      },
      async handleMovingOffChange() {
        var movingOffState = this.movingOffState;
        if(await HIDHandle.Set_MS_MovingOffState(movingOffState? 1 : 0) == false) {
          this.movingOffState = !movingOffState;
        }
      },
      async handleLightOffTimeChange() {
        if(await HIDHandle.Set_MS_LightOffTime(this.lightOffTime)) {
          this.lastLightOffTime = this.lightOffTime;
          this.$bus.$emit("updateSleepTime",this.lightOffTime);
        }
        else {
          this.lightOffTime = this.lastLightOffTime;
        }
      },
      getPixelColor(event,imgUrl) {
        const img = new Image();
        img.src = imgUrl;
        img.onload = async () => {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
          canvas.width = img.width * this.widthRatio;
          canvas.height = img.height * this.heightRatio;
          ctx.drawImage(img, 0, 0);
          const rect = canvas.getBoundingClientRect();
          const x = event.offsetX / this.widthRatio;
          const y = event.offsetY / this.heightRatio;

          const pixelData = ctx.getImageData(x, y, 1, 1);
          // 假设我们只关心点击的这个图片的中心像素颜色
          var color = pixelData.data;

          if(await HIDHandle.Set_MS_LightColor(color)) {
            this.color = color;
            this.colorToRgb();
          }        
        };
      },
      async handleColorChange() {
        var color = [this.r,this.g,this.b];
        console.log("handleColorChange",color);
        if(await HIDHandle.Set_MS_LightColor(color)) {
          this.color = color;
          this.rgbToColor();
        } 
        else {
          this.colorToRgb();
        }
      },
      colorToRgb() {
        this.r = this.color[0];
        this.g = this.color[1];
        this.b = this.color[2];

        this.backColor = UserConvert.Buffer_To_Color(this.color,0);
      },
      rgbToColor() {
        this.color[0] = this.r;
        this.color[1] = this.g;
        this.color[2] = this.b; 

        this.backColor = UserConvert.Buffer_To_Color(this.color,0);
      },
      updateDisableState() {
        var disableds = UserConvert.LightMode_To_Disable(this.mode);
        this.colorDisable = disableds.color;
        this.brightnessDisable = disableds.brightness;
        this.speedDisable = disableds.speed;
        this.lightState = this.mode != 0; 
      }     
    },
    created() {
      this.colorbarSrc = window.location.href + "img/color_bar.png";
      this.presetPath = window.location.href + "img/colors/color";

      this.$bus.$on("setMouseDefaultCfg",deviceCfg => {
        if(typeof deviceCfg.lightEffect != "undefined") {
          this.mode = deviceCfg.lightEffect.mode;
          this.updateDisableState();
          this.lastBrightness = this.brightness = deviceCfg.lightEffect.brightness;
          this.lastSpeed = this.speed = deviceCfg.lightEffect.speed;
          this.movingOffState = deviceCfg.lightEffect.movingOffState;
          this.lastLightOffTime = this.lightOffTime = deviceCfg.sleepTime;
        }
      }); 

      this.$bus.$on("updateMouseUI",value => {
        this.mode = value.lightEffect.state == false ? 0 : value.lightEffect.mode;
        this.updateDisableState();
        var disableds = UserConvert.LightMode_To_Disable(this.mode);
        this.colorDisable = disableds.color;
        this.brightnessDisable = disableds.brightness;
        this.speedDisable = disableds.speed;
          
        this.lastBrightness = this.brightness = value.lightEffect.brightness;
        this.lastSpeed = this.speed = value.lightEffect.speed;
        this.movingOffState = value.lightEffect.movingOffState;
        this.lastLightOffTime = this.lightOffTime = value.sleepTime;

        this.color = UserConvert.Color_To_Buffer(value.lightEffect.color);
        this.colorToRgb();
      });

      this.$bus.$on("languageChange",lang => {      
        if(typeof lang != 'undefined') {
          this.lightModeOptions = lang.LightModeOptions;
          this.lightOffTimeOptions = lang.LightOffTimeOptions;
        }
      });

      this.$bus.$on("widthResize",value => {
        this.widthRatio = value / 0.9;
      });  

      this.$bus.$on("heightResize",value => {
        this.heightRatio = value;
      });
      
      this.$bus.$on("updateLightOffTime",value => {
        this.lastLightOffTime = this.lightOffTime = value;
      }); 
    },
    beforeDestroy() {
      this.$bus.$off("setMouseDefaultCfg");
      this.$bus.$off("updateMouseUI");
      this.$bus.$off("languageChange");
      this.$bus.$off("updateLightOffTime");
    }
  }
</script>

<style lang="less" scoped>
.light_height {
  height: 35vh;
}

.moving_height {
  height: 14vh;
}

.normal_height {
  height: 60vh;
}

.text_width {
  width: calc(var(--light-normal-width) * 0.6);
}

.el-input-number {
  margin: 5px;
}

.light_margin {
  margin: 0px 10px;
}

:deep(.el-switch__core) {
  width: var(--moving-switch-width) !important;
  height: calc(var(--moving-switch-width) * 0.5);
  border-radius: calc(var(--moving-switch-width) * 0.25);
}

:deep(.el-switch__core:after) {
  width: calc(var(--moving-switch-width) * 0.4);
  height: calc(var(--moving-switch-width) * 0.4);  
  top: calc(var(--moving-switch-width) * 0.025);
  left: calc(var(--moving-switch-width) * 0.05);
}

:deep(.el-switch.is-checked .el-switch__core::after) {
  left: calc(var(--moving-switch-width) * 0.025) !important;
  margin-left: calc(var(--moving-switch-width) * 0.5);
}
</style>