<template>
  <div class="flex_column_evenly">
    <div class="flex_row" style="align-items: center;">
      <i class="incfont el-icon-title"></i>
      <span lang="ReportRate" class="margin_left">回报率</span>
    </div>
    <div class="flex_row">
      <div
      v-for="(value, index) in reportRates"
      :key="index"
      :class="index > 0?'report_margin_left':''">
      <el-button 
      :class="reportRate == index?'reportRate_checked':'reportRate_unchecked'"
      class="reportRate_button_width"
      @click="handleReportRateClick(index)">{{value.option}}</el-button>
      </div>
    </div>
  </div>
</template>

<script>
  import HIDHandle from '@/assets/js/HIDHandle';
  
  export default {
    data() {
      return {
        reportRates:[],
        defaultReportRates:[],
        reportRate:3,
        maxReportRate:8000,
      }
    },
    methods: {
      reportRateToIndex(reportRate) {
        var tmp = reportRate > this.maxReportRate ? this.maxReportRate : reportRate;
        var found = false;
        for(var i = 0;i < this.reportRates.length;i++)
        {
          var report = this.reportRates[i].rate;
          if(report == tmp)
          {
            this.reportRate = i;
            found = true;
            break;
          }
        }
        //如果读取的值超过当前最大值，就显示当前最大值
        if(found == false) {
          this.reportRate = this.reportRates[this.reportRates.length - 1].value;
        }
        this.setSensorCorder();
      },
      async handleReportRateClick(index) {     
        var report = this.reportRates[index].rate;
        
        if(await HIDHandle.Set_MS_ReportRate(report)) {
          this.reportRate = index;

          this.setSensorCorder();
        }
      },
      setSensorCorder() {
        if(HIDHandle.deviceInfo.isWired) {
          this.$bus.$emit("setSensorCorder",true);
        }
        else {
          if(this.reportRates[this.reportRate].rate > 1000) {
            this.$bus.$emit("setSensorCorder",true);
          }
          else {
            this.$bus.$emit("setSensorCorder",false);
          }
        }
      }
    },
    created() {
      this.$bus.$on("setMouseDefaultCfg",deviceCfg => {
        this.reportRateToIndex(deviceCfg.reportRate);
        this.maxReportRate = 8000;
      });

      this.$bus.$on("updateMaxReportRate",value => {
        console.log("updateMaxReportRate:",value)
        this.maxReportRate = value;

        this.reportRates = [];
        for(var i = 0;i < this.defaultReportRates.length;i++) {
          if(this.defaultReportRates[i].rate <= this.maxReportRate) {
            this.reportRates.push(this.defaultReportRates[i]);
          }
        }   
      });

      //根据当前设置所支持的最大报告率更新报告率列表
      this.$bus.$on("updateDeviceInfo",value => {
        this.reportRates = [];
        for(var i = 0;i < this.defaultReportRates.length;i++) {
          if(this.defaultReportRates[i].rate <= value.maxReportRate) {
            this.reportRates.push(this.defaultReportRates[i]);
          }
        }
        this.maxReportRate = value.maxReportRate < HIDHandle.deviceInfo.maxReportRate ? 
        value.maxReportRate : HIDHandle.deviceInfo.maxReportRate;
        console.log("updateDeviceInfo",this.maxReportRate,HIDHandle.deviceInfo.maxReportRate);
      });

      this.$bus.$on("updateMouseUI",value => {
        this.reportRateToIndex(value.reportRate);
      });

      this.$bus.$on("updateReportRate",value => {
        this.reportRateToIndex(value);
      });

      this.$bus.$on("languageChange",lang => {     
        if(typeof lang != 'undefined') {
          //获取默认报告率列表
          this.defaultReportRates = lang.ReportRates;
          this.reportRates = [];
          for(var i = 0;i < this.defaultReportRates.length;i++) {
            if(this.defaultReportRates[i].rate <= this.maxReportRate) {
              this.reportRates.push(this.defaultReportRates[i]);
            }
          }   
        }
      });
    },
    beforeDestroy() {
      this.$bus.$off("updateDeviceInfo");
      this.$bus.$off("updateMaxReportRate");
      this.$bus.$off("updateMouseUI");
      this.$bus.$off("updateReportRate");
      this.$bus.$off("languageChange");
    }
  }
</script>

<style lang="less" scoped>
.report_margin_left {
  margin-left: 4.5vw;
}
</style>