<template>
  <div style="position: relative;">
    <a :href="'//'+companyWeb" style="text-decoration: none" target="_blank"> {{companyWeb}}</a>
  </div>  
</template>

<script>
  export default {
    data() {
      return {
        companyWeb:""
      }
    },
    methods: {
      handleWebClick(url) {
        window.open(this.companyWeb);
      }
    },
    created() {
      this.$bus.$on("setDriverCfg",driverCfg => {
        this.companyWeb = driverCfg.web;
      });
    },
    beforeDestroy() {
      this.$bus.$off('setDriverCfg');
    }
  }
</script>

<style lang="less" scoped>
  a {
    font-size: var(--font-size);
    font-style: italic;
    position: fixed;
    bottom: 5vh;
    right: 15vh;
  }
</style>